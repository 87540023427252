<template>
  <div>
    <!-- header -->
    <video-background
      :src="require('@/assets/background.mp4')"
      :sources="[
        {
          src: require('@/assets/background.mp4'),
          res: 900,
          autoplay: false,
          poster: require('@/assets/images/poster.webp')
        },
        {
          src: require('@/assets/background.mp4'),
          res: 638,
          autoplay: false,
          poster: require('@/assets/images/poster.webp')
        }
      ]"
      class="header"
    >
      <div class="header-inner-container">
        <div>
          <v-img src="../assets/images/egfrnet_white.svg" class="img" alt="" />
        </div>
        <h1>
          Predicting pIC<sub>50</sub> for ligands against human EGFR-family
          proteins based on graph neural network
        </h1>
        <div class="predict">
          <div>TOTAL PREDICTION</div>
          <div>
            <number
              ref="prediction"
              :from="0"
              :to="predictor"
              :format="theFormat"
              :duration="2"
              easing="Power1.easeOut"
            />
          </div>
          <div style="margin-top: -10px">compounds</div>
        </div>
        <div>
          <button class="btn btn-secondary" v-on:click="goToPredictor">
            Predict Now
          </button>
        </div>
      </div>
    </video-background>
    <section class="ads-logo">
      <div>
        <div>
          <v-img contain class="img" src="../assets/images/kvis_color.svg" />
        </div>
        <div>
          <v-img contain class="img" src="../assets/images/vistec_color.svg" />
        </div>
        <div>
          <v-img contain class="img" src="../assets/images/scads_color.svg" />
        </div>
        <div>
          <v-img contain class="img" src="../assets/images/chula_logo.svg" />
        </div>
        <div>
          <v-img contain class="img" src="../assets/images/biosim_logo.svg" />
        </div>
      </div>
    </section>
    <section class="container-md">
      <div class="text-center">
        <v-img
          src="../assets/images/map.svg"
          style="width: 100%"
          data-aos="fade-up"
        />
      </div>
    </section>
    <section class="highlight">
      <div class="container-md">
        <div class="text-center">
          <v-img src="../assets/images/egfrnet_color.svg" class="logo" />
        </div>
        <p class="text-medium text-center my-40">
          <b>EGFRNet</b> is a novel graph convolutional network architecture for
          predicting pIC<sub>50</sub> of small molecules against human epidermal
          growth factor receptor (EGFR) family proteins (HER1-4). EGFR family is
          a drug target for lung, breast, etc. which has been reported as the
          most frequent cancer worldwide.
        </p>
        <p class="text-medium text-center my-40">
          The model architecture was inspired and adapted from a convolution
          spatial graph embedding layer (C-SGEL) which was constructed by graph
          convolutional networks incorporating especial molecular descriptors.
        </p>
        <p class="text-medium text-center my-40">
          Our propose provides a powerful strategy that potentially helps
          researcher overcome major challenges in drug discovery and development
          processes, leading to a reduction of failure to discover novel hit
          compounds.
        </p>
        <div class="text-center my-40">
          <v-img
            src="../assets/images/ligegfr_workflow.webp"
            style="width: 80%; margin: 0 auto;"
            class="transition-img"
          />
        </div>
        <div class="text-center">
          <a
            :href="'https://github.com/scads-biochem/LigEGFR'"
            target="_blank"
            rel="noopener noreferrer"
            ><button class="btn btn-primary">
              PYTHON EXECUTABLE SCRIPT
            </button></a
          >
        </div>
      </div>
    </section>
    <section class="container-md article-cols my-40">
      <div
        class="flex-center-row hidden-sm-and-down"
        data-aos="fade-right"
        data-aos-once="false"
      >
        <h2>
          EGFRNet is state-of-the-art for predicting pIC<sub>50</sub>&nbsp;of
          ligands against &nbsp;human EGFR family-proteins
        </h2>
        <div>
          EGFRNet outperformed baseline machine learning models for predicting
          pIC<sub>50</sub>
        </div>
        <div class="label-container">
          <div class="label">
            <div>Regression</div>
            <div>Q<sup>2</sup>(test) = 0.702</div>
            <div>RMSE = 0.802</div>
          </div>
          <div class="label">
            <div>Classification</div>
            <div>AUC = 0.942</div>
            <div>Accuracy = 0.867</div>
            <div>MCC = 0.732</div>
          </div>
        </div>
      </div>
      <div class="flex-center-row hidden-md-and-up">
        <h2>
          EGFRNet is state-of-the-art for predicting pIC<sub>50</sub>&nbsp;of
          ligands against &nbsp;human EGFR tyrosine kinase based-on a large
          scale dataset
        </h2>
        <div>
          EGFRNet outperformed baseline machine learning models for predicting
          pIC<sub>50</sub>&nbsp;and was notable for higher performance in hit
          compound classification, compared to molecular docking and machine
          learning approaches.
        </div>
        <div class="label-container">
          <div class="label">
            <div>Regression</div>
            <div>Q<sup>2</sup>(test) = 0.702</div>
            <div>RMSE = 0.802</div>
          </div>
          <div class="label">
            <div>Classification</div>
            <div>AUC = 0.942</div>
            <div>Accuracy = 0.867</div>
            <div>MCC = 0.732</div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <v-img
          src="../assets/images/roc_auc_all.webp"
          class="transition-img img"
        />
      </div>
    </section>
    <section class="items">
      <div>
        <h2>1</h2>
        <p>
          The molecular graph of atomic features has been applied to produce the
          best predictive performance.
        </p>
      </div>
      <div>
        <h2>2</h2>
        <p>
          Important 10,239 molecular descriptors from mixing molecular
          fingerprints has been used as the features for predicting
          pIC<sub>50</sub>.
        </p>
      </div>
      <div>
        <h2>3</h2>
        <p>
          The model was constructed from a large-scale and non-redundant
          experimental dataset to enhance the diversity of the small molecules.
        </p>
      </div>
    </section>
    <section class="container-articles hidden-sm-and-down">
      <div class="article-cols my-40">
        <div class="flex-center">
          <v-img
            contain
            src="../assets/images/c-sgen_propagation.webp"
            class="transition-img img"
          />
        </div>
        <div
          class="flex-center-row container-text"
          data-aos="fade-left"
          data-aos-once="false"
        >
          <h2 style="text-indent: -0.8em">
            + The molecular graph by C-SGEN algorithm
          </h2>
          <p>
            We applied the convolution spatial graph embedding network (C-SGEN)
            algorithm for the molecular graph representation. The C-SGEN is
            comprised of the convolution spatial graph embedding layer (C-SGEL)
            generated by graph convolutional networks.
          </p>
          <p class="text-light text-sm">
            Reference: Wang, X., Li, Z., Jiang, M., Wang, S., Zhang, S., &amp;
            Wei, Z. (2019). Molecule property prediction based on spatial graph
            embedding.&nbsp;<i class="text-light"
              >Journal of chemical information and modeling,&nbsp;59</i
            >(9), 3817-3828.
          </p>
        </div>
      </div>
      <div class="article-cols my-40">
        <div
          class="flex-center-row container-text"
          data-aos="fade-right"
          data-aos-once="false"
        >
          <h2 style="text-indent: -0.8em">
            + The non-hashed and hashed molecular descriptors as the input
            features
          </h2>
          <p>
            We performed 5 non-hashed and 11 hashed molecular fingerprints for
            improving a best performance and generalizing a model. The
            non-hashed and hashed molecular descriptors have been used for
            convolutional layers and fully connected layer, respectively.
          </p>
          <p class="text-light">
            - Non-hashed molecular fingerprints: Estate, Klekota-Roth, LINGO,
            PubChem, and MACCS <br />- Hashed molecular fingerprints: AtomPair,
            Circular, Daylight, Extended, Graph, Hybridization, FP2, FP3, FP4,
            Avalon, and RDKit
          </p>
        </div>
        <div class="flex-center">
          <v-img
            contain
            src="../assets/images/feature_pca.webp"
            class="transition-img img"
          />
        </div>
      </div>
      <div class="article-cols my-40">
        <div class="article-cols">
          <div class="flex-center">
            <v-img
              contain
              src="../assets/images/adan_ligegfr.webp"
              class="transition-img img"
            />
          </div>
          <div class="flex-center">
            <v-img
              contain
              src="../assets/images/y_scrambling_ligegfr.webp"
              class="transition-img img"
            />
          </div>
        </div>
        <div
          class="flex-center-row container-text"
          data-aos="fade-left"
          data-aos-once="false"
        >
          <h2 style="text-indent: -0.8em">+ A reliable and robust model</h2>
          <p>
            The model was validated a reliability and robustness by the
            y-scrambling and applicability domain analysis (ADAN). The results
            showed that the permuted data can not predict the pIC<sub>50</sub>
            and the model was comprised a large chemical space representing in
            the test set, commercial drug set, and clinical trial drug set.
          </p>
        </div>
      </div>
    </section>
    <section class="container-articles hidden-md-and-up">
      <div class="article-cols my-40">
        <div class="flex-center">
          <v-img
            contain
            src="../assets/images/c-sgen_propagation.webp"
            class="transition-img img"
          />
        </div>
        <div class="flex-center-row container-text">
          <h2 style="text-indent: -0.8em">
            + The molecular graph by C-SGEN algorithm
          </h2>
          <p>
            We applied the convolution spatial graph embedding network (C-SGEN)
            algorithm for the molecular graph representation. The C-SGEN is
            comprised of the convolution spatial graph embedding layer (C-SGEL)
            generated by graph convolutional networks.
          </p>
          <p class="text-light text-sm">
            Reference: Wang, X., Li, Z., Jiang, M., Wang, S., Zhang, S., &amp;
            Wei, Z. (2019). Molecule property prediction based on spatial graph
            embedding.&nbsp;<i class="text-light"
              >Journal of chemical information and modeling,&nbsp;59</i
            >(9), 3817-3828.
          </p>
        </div>
      </div>
      <div class="article-cols my-40">
        <div class="flex-center">
          <v-img
            contain
            src="../assets/images/feature_pca.webp"
            class="transition-img img"
          />
        </div>
        <div class="flex-center-row container-text">
          <h2 style="text-indent: -0.8em">
            + The non-hashed and hashed molecular descriptors as the input
            features
          </h2>
          <p>
            We performed 5 non-hashed and 11 hashed molecular fingerprints for
            improving a best performance and generalizing a model. The
            non-hashed and hashed molecular descriptors have been used for
            convolutional layers and fully connected layer, respectively.
          </p>
          <p class="text-light">
            - Non-hashed molecular fingerprints: Estate, Klekota-Roth, LINGO,
            PubChem, and MACCS <br />- Hashed molecular fingerprints: AtomPair,
            Circular, Daylight, Extended, Graph, Hybridization, FP2, FP3, FP4,
            Avalon, and RDKit
          </p>
        </div>
      </div>
      <div class="article-cols my-40">
        <div class="article-cols">
          <div class="flex-center">
            <v-img
              contain
              src="../assets/images/adan_ligegfr.webp"
              class="transition-img img"
            />
          </div>
          <div class="flex-center">
            <v-img
              contain
              src="../assets/images/y_scrambling_ligegfr.webp"
              class="transition-img img"
            />
          </div>
        </div>
        <div class="flex-center-row container-text">
          <h2 style="text-indent: -0.8em">+ A reliable and robust model</h2>
          <p>
            The model was validated a reliability and robustness by the
            y-scrambling and applicability domain analysis (ADAN). The results
            showed that the permuted data can not predict the pIC<sub>50</sub>
            and the model was comprised a large chemical space representing in
            the test set, commercial drug set, and clinical trial drug set.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  components: {},
  props: {
    setActive: Function
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    goToPredictor() {
      const path = "/predictor";
      if (this.$route.path !== path) this.$router.push(path);
      this.setActive("predictor");
    }
  },
  async mounted() {
    const data = await axios
      .get("https://ligegfr.manbaritone.dev/home-api")
      .catch(error => {
        if (error) {
          // console.log("Something went wrong, please submit again later");
          return;
        }
      });
    data != null ? (this.predictor = data.data.data) : null;
  },
  data() {
    return {
      predictor: 0
    };
  }
};
</script>

<style scoped>
.highlight {
  position: relative;
  padding: 40px 0px;
}
.highlight::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/graph.webp");
  background-size: contain;
  background-repeat: repeat-x;
  opacity: 0.2;
}

.highlight .container-md {
  position: relative;
}

.header {
  position: relative;
  background: #582e91;
  color: #ffffff;
}

.header .header-inner-container {
  padding-top: 120px;
  padding-bottom: 40px;
  text-align: center;
}

.header h1 {
  margin: 20px;
}

.header .img {
  margin: 0 auto;
  width: 300px;
}

header h1 {
  margin: 20px;
}

.predict {
  margin: 20px;
  font-size: 1.6em;
}

.predict > div {
  font-family: "IBMPlexSans Light";
  margin: 5px;
}

.predict > div:nth-child(2) {
  font-family: "IBMPlexSans Medium";
  font-size: 1.6em;
}

.ads-logo {
  background-color: #f2f2f2;
  padding: 10px 40px;
}

.ads-logo > div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  max-width: 960px;
  margin: 10px auto;
}

.ads-logo > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ads-logo > div > div:nth-child(1) .img {
  width: 105px;
}

.ads-logo > div > div:nth-child(2) .img {
  width: 150px;
}

.ads-logo > div > div:nth-child(3) .img {
  width: 200px;
}

.ads-logo > div > div:nth-child(4) .img {
  width: 150px;
}

.ads-logo > div > div:nth-child(5) .img {
  width: 100px;
}

.logo {
  margin: 0 auto;
  width: 250px;
}

hr {
  width: 80%;
  margin: 40px auto;
  border: 1px solid #f1f1f1;
}

.label-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.label {
  background-color: #582e91;
  color: #ffffff;
  padding: 20px 40px;
  text-align: center;
}

.label:first-child {
  margin-right: 20px;
}

.label > div:first-child {
  font-family: "IBMPlexSans Medium";
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #f8e131;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin: 40px 0px;
}

.items h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 2em;
}

.items > div {
  padding: 40px;
  color: #ffffff;
  font-size: 1.2em;
}

.items > div > div:last-child {
  font-family: "IBMPlexSans Light";
}

.items > div:nth-child(1) {
  background-color: #582e91;
}

.items > div:nth-child(2) {
  background-color: #8e67bb;
}

.items > div:nth-child(3) {
  background-color: #aa9bcd;
}

p {
  line-height: 1.4em;
}

.container-text {
  padding: 60px;
}

@media screen and (max-width: 991px) {
  .highlight::before {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .label-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
  .ads-logo {
    padding: 20px;
  }
  .ads-logo > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .ads-logo > div > div {
    padding: 0px 20px;
  }
  .ads-logo > div > div:nth-child(3) .img {
    height: 65px;
  }
  .ads-logo > div > div:nth-child(4) .img {
    height: 40px;
  }
  .ads-logo > div > div:nth-child(5) .img {
    height: 60px;
  }
  .ads-logo .img {
    height: 35px;
  }
}

@media screen and (max-width: 575px) {
  .container-articles {
    margin: 40px;
  }
  .header h1 {
    font-size: 1.2em;
  }
  .container-text {
    padding: 0px;
  }
  .predict {
    font-size: 1.2em;
  }
  .predict > div {
    margin: 0;
  }
  .ads-logo > div > div {
    padding: 5px 10px;
  }
  .items {
    grid-template-columns: 1fr;
  }
  .label-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  .label:first-child {
    margin-right: 0;
  }
  .label {
    padding: 10px 15px;
    word-wrap: anywhere;
  }
  .label > div:nth-child(1) {
    font-size: 1em;
  }
  .label > div:nth-child(2) {
    font-size: 0.8em;
  }
  .label > div:nth-child(3) {
    font-size: 0.8em;
  }
  .label > div:nth-child(4) {
    font-size: 0.8em;
  }
  .header .img {
    width: 70%;
    height: auto;
  }
}
</style>

<style>
.video-wrapper video {
  opacity: 0.3;
}
.video-buffering {
  opacity: 0.3;
}
.vue-responsive-videobg .videobg-content {
  position: relative !important;
}
.ads-logo .v-responsive__content {
  width: 0 !important;
}
</style>
